import { FC, useState } from 'react';

import { EyeClosed, EyeOpen } from '@assets/icons';
import { InputTypes } from '@core/enums/input';

interface InputProps {
  id?: string;
  name?: string;
  placeholder?: string;
  type?: InputTypes;
  label?: string;
  customInputClass?: string;
  error?: any;
  register?: Function;
  validations?: object;
}

const Input: FC<InputProps> = ({
  id = '',
  name = '',
  placeholder = '',
  type = InputTypes.TEXT,
  label = '',
  customInputClass = '',
  error = '',
  register,
  validations,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const errorClasses = error ? '!border-red-1 !focus:border-red-1' : '';

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <div className="space-y-1">
      <label htmlFor={name} className="text-sm">
        {label}
      </label>
      <div className="relative">
        <input
          id={id}
          name={name}
          {...(register ? register(name, { ...validations }) : {})}
          placeholder={placeholder}
          type={
            type === InputTypes.PASSWORD
              ? showPassword
                ? InputTypes.TEXT
                : InputTypes.PASSWORD
              : type
          }
          className={`h-10 w-full text-sm placeholder-gray-3 rounded focus:outline-none border border-gray-4 py-3 px-4 ${customInputClass} ${errorClasses}`}
        />
        {error && <span className="text-red-1 text-sm mt-1">{error}</span>}
        {type === InputTypes.PASSWORD && (
          <label
            htmlFor={name}
            className="flex items-center h-10 absolute inset-y-0 right-0 cursor-pointer px-2"
          >
            {showPassword ? (
              <EyeOpen onClick={toggleShowPassword} className="text-gray-1" />
            ) : (
              <EyeClosed onClick={toggleShowPassword} className="text-gray-1" />
            )}
          </label>
        )}
      </div>
    </div>
  );
};

export default Input;
