import { Navigate, Route, Routes } from 'react-router-dom';

import { PasswordActions } from '@core/enums/create-reset-password';
import {
  ForgotPassword,
  HandlePassword,
  Login,
  SelectWorkspace,
} from '@pages/index';

import RoutesPath from './constants';

const AllRoutes = () => {
  return (
    <Routes>
      <Route path={RoutesPath.LOGIN} element={<Login />} />
      <Route path={RoutesPath.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={RoutesPath.SELECT_WORKSPACE} element={<SelectWorkspace />} />
      <Route
        path={RoutesPath.RESET_PASSWORD}
        element={<HandlePassword type={PasswordActions.RESET} />}
      />
      <Route
        path={RoutesPath.CREATE_PASSWORD}
        element={<HandlePassword type={PasswordActions.CREATE} />}
      />
      <Route
        path={RoutesPath.ALL}
        element={<Navigate replace to={RoutesPath.LOGIN} />}
      />
    </Routes>
  );
};

export default AllRoutes;
