import { useState } from 'react';

import { ListboxButton } from '@headlessui/react';
import { isNil } from 'lodash';

import { AngleDown, AngleUp } from '@assets/icons';
import { Button } from '@components/index';

import SelectWorkspaceDropdown from './SelectWorkspaceDropdown';

const SelectWorkspaceRefComponent = ({
  label,
  isOpen,
}: {
  label: string;
  isOpen: boolean;
}) => {
  return (
    <ListboxButton className="w-full cursor-pointer focus:outline-none">
      <div className="w-full h-12 flex justify-between items-center px-2 border border-gray-4 rounded-md focus:outline-none">
        <div className="flex justify-start items-center truncate font-semibold whitespace-nowrap">
          {!label && (
            <div className="text-gray-4 text-sm">Select workspace</div>
          )}
          {!isNil(label) && <span className="truncate pl-2">{label}</span>}
        </div>
        {isOpen ? (
          <AngleUp className="text-gray-1" />
        ) : (
          <AngleDown className="text-gray-1" />
        )}
      </div>
    </ListboxButton>
  );
};

const SelectWorkSpaceForm = () => {
  const [workspaceSearchKeyword, setWorkspaceSearchKeyword] = useState<
    string | null
  >(null);
  const [activeParentWorkspaceId, setActiveParentWorkspaceId] = useState<
    number | null
  >(null);
  const options = [
    {
      id: 17236,
      clientId: 17714,
      clientUuid: '59309734',
      isActive: true,
      isPro: true,
      brandUrl: null,
      name: '[QA] Kratos',
    },
    {
      id: 17186,
      clientId: 17664,
      clientUuid: 'eadc489b',
      isActive: true,
      isPro: false,
      brandUrl: null,
      name: '[Test] Magnum Imported Testing',
    },
    {
      id: 16220,
      clientId: 17216,
      clientUuid: 'e244ff9c',
      isActive: true,
      isPro: true,
      brandUrl: 'http://ugg.com/',
      name: 'joel_test_1',
    },
    {
      id: 16992,
      clientId: 17473,
      clientUuid: '65ae3ee4',
      isActive: true,
      isPro: true,
      brandUrl: null,
      name: 'joel_test_1c',
    },
    {
      id: 5467,
      clientId: 6375,
      clientUuid: '55a7accf',
      isActive: true,
      isPro: true,
      brandUrl: null,
      name: 'Pencil Developer test 3',
    },
  ];

  return (
    <div className="w-full">
      <SelectWorkspaceDropdown
        options={options}
        setSearchKeyword={setWorkspaceSearchKeyword}
        setActiveParentId={setActiveParentWorkspaceId}
        PopperRefComponent={SelectWorkspaceRefComponent}
        classOverride="min-w-[256px] max-h-[215px]"
      />
      <div className="flex items-center mt-4">
        <Button
          variant="contained"
          label="Go to dashboard"
          onClick={() => {}}
          isDisabled
        />
      </div>
    </div>
  );
};

export default SelectWorkSpaceForm;
