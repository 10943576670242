import { FC, useRef, useState } from 'react';

import { Portal } from '@headlessui/react';
import { usePopper } from 'react-popper';

export interface PopperProps {
  placement?:
    | 'top'
    | 'top-start'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'left-start'
    | 'auto';
  PopComponent: any;
  RefComponent: any;
}

const Popper: FC<PopperProps> = ({
  placement = 'top',
  PopComponent,
  RefComponent,
}) => {
  const popperElRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'flip',
        enabled: true,
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <>
      <div ref={setReferenceElement}>{RefComponent()}</div>
      <Portal>
        <div
          ref={popperElRef}
          style={{ ...styles.popper, zIndex: 50 }}
          {...attributes.popper}
        >
          {PopComponent({
            height: referenceElement?.clientHeight,
            width: referenceElement?.clientWidth,
            setPopperElement,
            popperElRef,
          })}
        </div>
      </Portal>
    </>
  );
};

export default Popper;
