import { Fragment } from 'react';

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import Routes from './routes';

import './App.css';

const App = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

createRoot(document.getElementById('root') as HTMLElement).render(
  <Fragment>
    <App />
  </Fragment>,
);
