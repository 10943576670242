import { FC } from 'react';

import { Button, Input, SuccessMessageTemplate } from '@components/index';
import {
  PasswordActions,
  PasswordInputTypes,
} from '@core/enums/create-reset-password';
import { InputTypes } from '@core/enums/input';
import { AuthLayout } from '@layout/index';

interface HandlePasswordProps {
  type: PasswordActions;
}

const handlePasswordLabels = {
  [PasswordActions.CREATE]: {
    title: 'Welcome Back!',
    subTitle: 'Set the password for your account',
    buttonLabel: 'Save',
    loginLinkLabel: 'Already have an account?',
  },
  [PasswordActions.RESET]: {
    title: 'Create a new password!',
    subTitle: 'Kindly enter the new password',
    buttonLabel: 'Update Password',
    loginLinkLabel: 'Remember your password?',
  },
};

const HandlePassword: FC<HandlePasswordProps> = ({ type }) => {
  const { title, subTitle, buttonLabel, loginLinkLabel } =
    handlePasswordLabels[type];
  const isResetPasswordSuccess = false;

  if (isResetPasswordSuccess && type === PasswordActions.RESET)
    return (
      <SuccessMessageTemplate
        heading="Password changed"
        subHeading="Your password has been changed successfully"
        buttonLabel="Back to login"
        onButtonClick={() => {}}
      />
    );

  return (
    <AuthLayout>
      <div className="flex flex-col w-full gap-2 justify-center items-center">
        <div className="text-2xl font-bold">{title}</div>
        <div className="text-sm max-w-[412px] text-center">{subTitle}</div>
      </div>
      <form className="w-full space-y-4">
        <div className="space-y-1">
          <Input
            id={PasswordInputTypes.PASSWORD}
            placeholder="Choose new password"
            name={PasswordInputTypes.PASSWORD}
            label="New Password"
            type={InputTypes.PASSWORD}
            customInputClass="focus:border-yellow-1 focus:bg-yellow-2"
          />
        </div>

        <Input
          id={PasswordInputTypes.CONFIRM_PASSWORD}
          placeholder="Confirm new password"
          name={PasswordInputTypes.CONFIRM_PASSWORD}
          label="Confirm new password"
          type={InputTypes.PASSWORD}
          customInputClass="focus:border-yellow-1 focus:bg-yellow-2"
        />

        <Button variant="contained" onClick={() => {}} label={buttonLabel} />
      </form>
      <div className="flex items-center">
        <span className="text-sm">{loginLinkLabel}</span>
        <Button
          label="Try logging in"
          variant="text"
          onClick={() => {}}
          classOverride="!text-sm ml-1"
        />
      </div>
    </AuthLayout>
  );
};

export default HandlePassword;
