import { EmailIcon } from '@assets/icons';
import { Button, Input, SuccessMessageTemplate } from '@components/index';
import { InputTypes } from '@core/enums/input';
import { AuthLayout } from '@layout/index';

const ForgotPassword = () => {
  // To be changed
  const isRecoveryEmailSent = false;

  if (isRecoveryEmailSent)
    return (
      <SuccessMessageTemplate
        heading="Recovery email sent!"
        subHeading="If this email address belongs to an account, you’ll receive an
           email containing a link to reset your password"
      />
    );

  return (
    <AuthLayout>
      <div className="flex flex-col w-full gap-2 justify-center items-center">
        <div className="text-2xl font-bold">Forgot Password</div>
        <div className="text-sm max-w-[412px] text-center">
          Input your email below and we will send you a link to reset your
          password.
        </div>
      </div>
      <form className="flex flex-col w-full gap-4 justify-center">
        <Input
          placeholder="Email ID"
          type={InputTypes.TEXT}
          label="Email"
          customInputClass="focus:border-yellow-1 focus:bg-yellow-2"
        />
        <Button
          variant="contained"
          label="Reset password"
          IconLeftComponent={EmailIcon}
          onClick={() => null}
        />
      </form>
      <div className="flex items-center">
        <span className="text-sm">Or</span>
        <Button
          label="Login"
          variant="text"
          onClick={() => {}}
          classOverride="!text-sm !ml-1"
        />
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;
