import { FC } from 'react';

import { isNil } from 'lodash';
import { useForm } from 'react-hook-form';

import { Button, Input } from '@components/index';
import { InputTypes } from '@core/enums/input';

interface LoginFormProps {
  isEmailOnly?: boolean;
  iconComponent?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null;
  buttonLabel?: string;
}

const LoginForm: FC<LoginFormProps> = ({
  isEmailOnly = false,
  iconComponent = () => null,
  buttonLabel = 'Login',
}) => {
  const {
    register,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  return (
    <form className="space-y-4 animate-slide-in overflow-hidden">
      <Input
        id="email"
        placeholder="Email ID"
        name="email"
        label="Email"
        register={register}
        validations={{
          required: {
            value: true,
            message: 'Please enter an email address',
          },
        }}
        type={InputTypes.EMAIL}
        customInputClass="focus:border-yellow-1 focus:bg-yellow-2"
        error={isNil(errors.email?.message) ? '' : errors.email.message}
      />
      {!isEmailOnly && (
        <div>
          <div className="mb-6 relative">
            <Input
              id="password"
              placeholder="Enter your passsword"
              name="password"
              label="Password"
              type={InputTypes.PASSWORD}
              customInputClass="focus:border-yellow-1 focus:bg-yellow-2"
              register={register}
              validations={{
                required: {
                  value: true,
                  message: 'Please enter a password',
                },
              }}
              error={
                isNil(errors.password?.message) ? '' : errors.password.message
              }
            />
            <div className="absolute right-0 top-20">
              <Button
                label="Forgot Password?"
                onClick={() => {}}
                variant="text"
              />
            </div>
          </div>
        </div>
      )}
      <Button
        variant="contained"
        onClick={() => {}}
        label={buttonLabel}
        IconLeftComponent={iconComponent}
      />
    </form>
  );
};

export default LoginForm;
