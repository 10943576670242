import { useMemo, useState } from 'react';

import { EmailIcon } from '@assets/icons';
import { Button } from '@components/index';
import { AuthLayout } from '@layout/index';

import Link from './components/link';
import LoginForm from './components/LoginForm';
import { LoginInputFlow } from './enums';

const Login = () => {
  const [activeFlow, setActiveFlow] = useState<LoginInputFlow | null>(null);

  const showLoginForm = useMemo(
    () => activeFlow === LoginInputFlow.EMAIL,
    [activeFlow],
  );
  const showSSOEmail = useMemo(
    () => activeFlow === LoginInputFlow.SSO,
    [activeFlow],
  );

  const toggleActiveFlow = (newFlow: LoginInputFlow) =>
    setActiveFlow(newFlow === activeFlow ? null : newFlow);

  return (
    <AuthLayout>
      <div className="flex flex-col max-w-[476px] w-full gap-4">
        <div
          className={`w-full overflow-hidden transition-all duration-300 ${
            showSSOEmail
              ? 'max-h-[160px] opacity-1 pointer-events-auto mx-auto'
              : 'max-h-0 opacity-0 pointer-events-none'
          }`}
        >
          {showSSOEmail && (
            <LoginForm buttonLabel="Continue with SSO" isEmailOnly />
          )}
        </div>
        {!showSSOEmail && (
          <Button
            label="Sign in with SSO"
            onClick={() => toggleActiveFlow(LoginInputFlow.SSO)}
          />
        )}
        <div className="w-full">
          <div className="flex items-center justify-center">
            <div className={'h-[1px] w-4/5 bg-gray-2'} />
            <span className="text-gray-3 text-xs items-center justify-center flex w-14">
              or
            </span>
            <div className="h-[1px] w-4/5 bg-gray-2" />
          </div>
        </div>
        {!showLoginForm && (
          <Button
            label="Continue with Email"
            IconLeftComponent={EmailIcon}
            onClick={() => toggleActiveFlow(LoginInputFlow.EMAIL)}
          />
        )}
        <div
          className={`w-full overflow-hidden transition-all duration-500 ${
            showLoginForm
              ? 'max-h-[290px] opacity-1 pointer-events-auto mx-auto'
              : 'max-h-0 opacity-0 pointer-events-none'
          }`}
        >
          {showLoginForm && (
            <LoginForm
              buttonLabel="Continue with Email"
              iconComponent={EmailIcon}
            />
          )}
        </div>
      </div>
      <div className="max-w-[412px] text-sm text-center">
        By continuing, you confirm that you agree to Brand DNAi{' '}
        <Link label="Terms of service" /> and <Link label="Privacy Policy" />
      </div>
    </AuthLayout>
  );
};

export default Login;
