import { FC, Fragment, useRef } from 'react';

import {
  Listbox,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { isEmpty } from 'lodash';
import { Waypoint } from 'react-waypoint';

import { Input, Popper } from '@components/index';

import WorkspaceOptionItem from './WorkspaceOptionItem';

interface SelectWorkspaceDropdownProps {
  options: any;
  selectedOption?: any;
  hasMoreOptions?: boolean;
  isLoadingData?: boolean;
  fetchMoreOptions?: () => void;
  setSearchKeyword: (keyword: string | null) => void;
  setActiveParentId: (parentId: number | null) => void;
  PopperRefComponent: ({
    label,
    isOpen,
  }: {
    label: string;
    isOpen: boolean;
  }) => JSX.Element;
  classOverride?: string;
  children?: React.ReactNode;
}

const SelectWorkspaceDropdown: FC<SelectWorkspaceDropdownProps> = ({
  options,
  selectedOption,
  hasMoreOptions,
  isLoadingData,
  fetchMoreOptions,
  setSearchKeyword,
  setActiveParentId,
  PopperRefComponent,
  classOverride,
  children,
}) => {
  const searchInput = useRef<HTMLInputElement>(null);

  const resetOptions = () => {
    setSearchKeyword(null);
    setActiveParentId(null);
  };

  const handleFetchChildren = (clientId: number | null) => {
    setSearchKeyword(null);
    if (searchInput.current) {
      searchInput.current.value = '';
    }
    setActiveParentId(clientId);
  };

  return (
    <Listbox>
      {({ open }) => (
        <Popper
          placement="bottom-start"
          PopComponent={({ popperElRef, setPopperElement, width }: any) => (
            <Transition
              afterLeave={() => {
                setPopperElement(null);
                resetOptions();
              }}
              as={Fragment}
              beforeEnter={() => setPopperElement(popperElRef.current)}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              show={open}
            >
              <ListboxOptions
                className={`flex flex-col border border-gray-2 bg-white rounded shadow-lg focus:outline-none ${classOverride}`}
                static
                style={{ width }}
              >
                <div className="p-2">
                  <Input placeholder="Search" />
                </div>
                {!isLoadingData && isEmpty(options) && (
                  <div className="flex justify-center p-6 text-gray-6">
                    No workspaces found
                  </div>
                )}
                {!isLoadingData && !isEmpty(options) && (
                  <span className="flex-grow overflow-auto">
                    {options.map((option: any) => (
                      <ListboxOption key={option.clientId} value={option}>
                        <WorkspaceOptionItem
                          option={option}
                          isSelected={
                            option.clientId === selectedOption?.clientId
                          }
                          onNextClick={e => {
                            e.stopPropagation();
                            handleFetchChildren(option.clientId);
                          }}
                        />
                      </ListboxOption>
                    ))}
                    {hasMoreOptions && (
                      <Waypoint
                        onEnter={() => {
                          if (hasMoreOptions && fetchMoreOptions) {
                            fetchMoreOptions();
                          }
                        }}
                      >
                        <span className="text-xs">Loading...</span>
                      </Waypoint>
                    )}
                  </span>
                )}
                {children}
              </ListboxOptions>
            </Transition>
          )}
          RefComponent={() => (
            <PopperRefComponent
              label={selectedOption?.name || ''}
              isOpen={open}
            />
          )}
        />
      )}
    </Listbox>
  );
};

export default SelectWorkspaceDropdown;
